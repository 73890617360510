import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { getStore } from '../common/store'

const VideoRedirect = () => {
  const { shortPostId, shortDonorId } = useParams()

  useEffect(() => {
    const getRedirectUrl = async () => {
      try {
        const { charityId, adminServiceUrl } = getStore().getState().config
        const response = await axios.get(`${adminServiceUrl}/api/mms/redirect/${charityId}`, {
          params: {
            shortPostId,
            shortDonorId
          }
        })
        if (response.data) {
          window.location.replace(response.data)
        } else {
          console.error('No redirect URL found in the response.')
        }
      } catch (error) {
        console.error('Error contacting backend:', error)
        window.location.replace('/')
      }
    }

    getRedirectUrl()
  }, [shortPostId, shortDonorId])

  return null
}

export default VideoRedirect
